(function($) {
    'use strict';

    $('.main-slider').slick({
        arrows: false,
        dots: true,
        autoplay: true,
        autoplaySpeed: 4000
    });

    $('.image-slider').each(function() {
        if($(this).children().length > 1) {
            $(this).slick({
                arrows: false,
                dots: true,
                autoplay: true,
                autoplaySpeed: 4000
            });
        }
    });

    $('[data-toggle="custom_collapse"]').on('click', function(event) {
        event.preventDefault();
        $(this).closest('.collapse-container').find('.collapse').collapse('hide');
        var target = $(this).data('target');
        $(target).collapse('show');
        return false;
    });

    $('.collapse-close').on('click', function(event) {
        event.preventDefault();
        $(this).closest('.collapse').collapse('hide');
        return false;
    });

    $('#product-filter').on('change', function() {
        window.location.href = $(this).val();
    });

    $('.youtube-video-link').on('click', function() {
        $(this).html('<iframe class="embed-responsive-item" src="https://www.youtube.com/embed/' + $(this).data('video-id') + '?autoplay=1" frameborder="0" allowfullscreen></iframe>');
        return false;
    });

    $.fn.customValidate = function (rules) {
        var $form = $(this);
        var holder = $(this).closest('.form-holder');

        var showSuccess = function() {
            $form.hide();
            holder.find('.form-message').hide();
            holder.find('.form-message-success').show();
        };

        var showError = function() {
            holder.find('.form-message').hide();
            holder.find('.form-message-error').show();
        };

        $(this).validate({
            lang : 'de',
            rules: rules,
            submitHandler: function (form, event) {
                event.preventDefault();
                $.ajax({
                    type: $form.attr('method'),
                    url: $form.attr('action'),
                    data: $form.serialize()
                }).done(function (data) {
                    if (data.success === true) {
                        showSuccess();
                    } else {
                        showError();
                    }
                }).fail(function () {
                    showError();
                });
            },
            highlight: function (element) {
                $(element).addClass('is-invalid');
            },
            unhighlight: function (element) {
                $(element).removeClass('is-invalid');
            },
            errorElement: 'div',
            errorClass: 'invalid-feedback',
            errorPlacement: function (error, element) {
                if (element.attr('type') === 'checkbox' || element.attr('type') === 'radio') {
                    element.closest('.checkbox').append(error);
                } else {
                    error.insertAfter(element);
                }
            }
        });
    };

    $('.newsletter-form').customValidate({
        name: 'required',
        email: {
            required: true,
            email: true
        }
    });

}(jQuery));
