(function() {
    'use strict';
    var button = document.getElementById('cookie-close-btn');
    var banner = document.getElementById('cookie-banner');
    var bannerSpacer = document.getElementById('cookie-spacer');

    if(getCookie('cookies_accepted') != 'yes') {
        banner.style.display = 'block';
        bannerSpacer.style.display = 'block';
    }

    button.addEventListener('click', function(event) {
        event.preventDefault();
        var domain = window.location.hostname.replace(/^[^\.]*\./, '.');
        setCookie('cookies_accepted', 'yes', 365, domain);
        banner.style.display = 'none';
        bannerSpacer.style.display = 'none';
        return false;
    });

    function setCookie(cname, cvalue, exdays, domain) {
        var d = new Date();
        d.setTime(d.getTime() + (exdays * 24 * 60 * 60 * 1000));
        var expires = 'expires='+d.toUTCString();
        document.cookie = cname + '=' + cvalue + '; ' + expires + ';path=/';
    }

    function getCookie(cname) {
        var name = cname + '=';
        var ca = document.cookie.split(';');
        for(var i=0; i<ca.length; i++) {
            var c = ca[i];
            while (c.charAt(0)==' ') c = c.substring(1);
            if (c.indexOf(name) == 0) return c.substring(name.length,c.length);
        }
        return '';
    }
}());
